import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/noahgilmore.com/noahgilmore.com/src/components/BlogPageLayout.jsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`I use `}<a parentName="p" {...{
        "href": "https://gatsbyjs.org"
      }}>{`Gatsby`}</a>{` as a static site generator to build this blog using React. As part of this, I wanted a simple component to display a static image, something like this:`}</p>
    <pre {...{
      "className": "dark-default-dark vscode-highlight",
      "data-language": "jsx"
    }}><code parentName="pre" {...{
        "className": "vscode-highlight-code"
      }}><span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk17"
          }}>{`<`}</span><span parentName="span" {...{
            "className": "mtk10"
          }}>{`Image`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`filename`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`=`}</span><span parentName="span" {...{
            "className": "mtk8"
          }}>{`"myimage.png"`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk17"
          }}>{`/>`}</span></span></code></pre>
    <p>{`Surprisingly, this is hard to do in Gatsby. The default Image component generated by Gatsby uses `}<inlineCode parentName="p">{`StaticQuery`}</inlineCode>{`:`}</p>
    <pre {...{
      "className": "dark-default-dark vscode-highlight",
      "data-language": "jsx"
    }}><code parentName="pre" {...{
        "className": "vscode-highlight-code"
      }}><span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk15"
          }}>{`import`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`Img`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk15"
          }}>{`from`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk8"
          }}>{`'gatsby-image'`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}></span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk4"
          }}>{`const`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`Image`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` = () `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`=>`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` (`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`  `}</span><span parentName="span" {...{
            "className": "mtk17"
          }}>{`<`}</span><span parentName="span" {...{
            "className": "mtk10"
          }}>{`StaticQuery`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    `}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`query`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`=`}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`{`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`graphql`}</span><span parentName="span" {...{
            "className": "mtk8"
          }}>{`\``}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk8"
          }}>{`      query {`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk8"
          }}>{`        placeholderImage: file(relativePath: { eq: "gatsby-astronaut.png" }) {`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk8"
          }}>{`          childImageSharp {`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk8"
          }}>{`            fluid(maxWidth: 300) {`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk8"
          }}>{`              ...GatsbyImageSharpFluid`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk8"
          }}>{`            }`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk8"
          }}>{`          }`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk8"
          }}>{`        }`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk8"
          }}>{`      }`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk8"
          }}>{`    \``}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`}`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    `}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`render`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`=`}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`{`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`data`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`=>`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk17"
          }}>{`<`}</span><span parentName="span" {...{
            "className": "mtk10"
          }}>{`Img`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`fluid`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`=`}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`{`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`data`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`placeholderImage`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`childImageSharp`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`fluid`}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`}`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk17"
          }}>{`/>`}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`}`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`  `}</span><span parentName="span" {...{
            "className": "mtk17"
          }}>{`/>`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`)`}</span></span></code></pre>
    <p>{`Simple enough - I figured I could make it customizable using props:`}</p>
    <pre {...{
      "className": "dark-default-dark vscode-highlight",
      "data-language": "jsx"
    }}><code parentName="pre" {...{
        "className": "vscode-highlight-code"
      }}><span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk4"
          }}>{`const`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`Image`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` = (`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`props`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`) `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`=>`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` (`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`  `}</span><span parentName="span" {...{
            "className": "mtk17"
          }}>{`<`}</span><span parentName="span" {...{
            "className": "mtk10"
          }}>{`StaticQuery`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    `}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`query`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`=`}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`{`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`graphql`}</span><span parentName="span" {...{
            "className": "mtk8"
          }}>{`\``}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk8"
          }}>{`      query {`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk8"
          }}>{`        placeholderImage: file(relativePath: { eq: `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`\${`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`props`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`filename`}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`}`}</span><span parentName="span" {...{
            "className": "mtk8"
          }}>{` }) {`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk8"
          }}>{`          childImageSharp {`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk8"
          }}>{`            fluid(maxWidth: 300) {`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk8"
          }}>{`              ...GatsbyImageSharpFluid`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk8"
          }}>{`            }`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk8"
          }}>{`          }`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk8"
          }}>{`        }`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk8"
          }}>{`      }`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk8"
          }}>{`    \``}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`}`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    `}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`render`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`=`}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`{`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`data`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`=>`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk17"
          }}>{`<`}</span><span parentName="span" {...{
            "className": "mtk10"
          }}>{`Img`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`fluid`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`=`}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`{`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`data`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`placeholderImage`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`childImageSharp`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`fluid`}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`}`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk17"
          }}>{`/>`}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`}`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`  `}</span><span parentName="span" {...{
            "className": "mtk17"
          }}>{`/>`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`)`}</span></span></code></pre>
    <p>{`Unfortunately this doesn't actually work - `}<inlineCode parentName="p">{`StaticQuery`}</inlineCode>{` is called "Static" because it's compiled and doesn't support string interpolation in its template literal. If you try to do this, you'll see an error like this on build:`}</p>
    <pre {...{
      "className": "dark-default-dark vscode-highlight",
      "data-language": "nohighlight"
    }}><code parentName="pre" {...{
        "className": "vscode-highlight-code"
      }}><span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`Error: BabelPluginRemoveGraphQL: String interpolations are not allowed in graphql fragments. Included fragments should be referenced as \`...MyModule_foo\`.`}</span></code></pre>
    <p>{`This behavior is `}<a parentName="p" {...{
        "href": "https://www.gatsbyjs.org/docs/static-query/"
      }}>{`documented`}</a>{` by Gatsby, and that documentation notes that the alternative is to use a page query. I didn't want to put a query on every page with images though, so I started investigating other options.`}</p>
    <p>{`I came across `}<a parentName="p" {...{
        "href": "https://medium.freecodecamp.org/how-i-made-my-portfolio-website-blazing-fast-with-gatsby-82ccddc2f671"
      }}>{`this post`}</a>{` by someone who had been doing some fancier image querying with Gatsby - they had the foresight to `}<em parentName="p">{`query all the images upfront`}</em>{` by using the `}<inlineCode parentName="p">{`allFile`}</inlineCode>{` query, and filter them down with props later. Based on that, I implemented the following component, which works great and includes all the fun functionality that `}<a parentName="p" {...{
        "href": "https://www.gatsbyjs.org/packages/gatsby-image/"
      }}>{`gatsby-image`}</a>{` offers:`}</p>
    <pre {...{
      "className": "dark-default-dark vscode-highlight",
      "data-language": "jsx"
    }}><code parentName="pre" {...{
        "className": "vscode-highlight-code"
      }}><span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk15"
          }}>{`import`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`Img`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk15"
          }}>{`from`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk8"
          }}>{`'gatsby-image'`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}></span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk4"
          }}>{`const`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`Image`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` = (`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`props`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`) `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`=>`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` (`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`  `}</span><span parentName="span" {...{
            "className": "mtk17"
          }}>{`<`}</span><span parentName="span" {...{
            "className": "mtk10"
          }}>{`StaticQuery`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    `}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`query`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`=`}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`{`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`graphql`}</span><span parentName="span" {...{
            "className": "mtk8"
          }}>{`\``}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk8"
          }}>{`      query {`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk8"
          }}>{`        images: allFile {`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk8"
          }}>{`          edges {`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk8"
          }}>{`            node {`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk8"
          }}>{`              relativePath`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk8"
          }}>{`              name`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk8"
          }}>{`              childImageSharp {`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk8"
          }}>{`                sizes(maxWidth: 600) {`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk8"
          }}>{`                  ...GatsbyImageSharpSizes`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk8"
          }}>{`                }`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk8"
          }}>{`              }`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk8"
          }}>{`            }`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk8"
          }}>{`          }`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk8"
          }}>{`        }`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk8"
          }}>{`      }`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk8"
          }}>{`    \``}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`}`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}></span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    `}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`render`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`=`}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`{`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`data`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`) `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`=>`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` {`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`      `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`const`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`image`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` = `}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`data`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`images`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`edges`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`find`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`n`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`=>`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` {`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`        `}</span><span parentName="span" {...{
            "className": "mtk15"
          }}>{`return`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`n`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`node`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`relativePath`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`includes`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`props`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`filename`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`);`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`      });`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`      `}</span><span parentName="span" {...{
            "className": "mtk15"
          }}>{`if`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` (!`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`image`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`) { `}</span><span parentName="span" {...{
            "className": "mtk15"
          }}>{`return`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`null`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`; }`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`      `}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`      `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`const`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`imageSizes`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` = `}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`image`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`node`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`childImageSharp`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`sizes`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`;`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`      `}</span><span parentName="span" {...{
            "className": "mtk15"
          }}>{`return`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` (`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`        `}</span><span parentName="span" {...{
            "className": "mtk17"
          }}>{`<`}</span><span parentName="span" {...{
            "className": "mtk10"
          }}>{`Img`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`          `}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`alt`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`=`}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`{`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`props`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`alt`}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`}`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`          `}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`sizes`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`=`}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`{`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`imageSizes`}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`}`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`        `}</span><span parentName="span" {...{
            "className": "mtk17"
          }}>{`/>`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`      );`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    }`}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`}`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`  `}</span><span parentName="span" {...{
            "className": "mtk17"
          }}>{`/>`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`)`}</span></span></code></pre>
    <p>{`Hopefully that snippet helps if you're trying to do something similar. If it does, `}<a parentName="p" {...{
        "href": "https://twitter.com/noahsark769"
      }}>{`let me know`}</a>{` 👋`}</p>

    <style {...{
      "className": "vscode-highlight-styles"
    }}>{`
  
  .dark-default-dark {
background-color: #1E1E1E;
color: #D4D4D4;
}

.dark-default-dark .mtk1 { color: #D4D4D4; }
.dark-default-dark .mtk2 { color: #1E1E1E; }
.dark-default-dark .mtk3 { color: #6A9955; }
.dark-default-dark .mtk4 { color: #569CD6; }
.dark-default-dark .mtk5 { color: #D16969; }
.dark-default-dark .mtk6 { color: #D7BA7D; }
.dark-default-dark .mtk7 { color: #B5CEA8; }
.dark-default-dark .mtk8 { color: #CE9178; }
.dark-default-dark .mtk9 { color: #646695; }
.dark-default-dark .mtk10 { color: #4EC9B0; }
.dark-default-dark .mtk11 { color: #DCDCAA; }
.dark-default-dark .mtk12 { color: #9CDCFE; }
.dark-default-dark .mtk13 { color: #000080; }
.dark-default-dark .mtk14 { color: #F44747; }
.dark-default-dark .mtk15 { color: #C586C0; }
.dark-default-dark .mtk16 { color: #6796E6; }
.dark-default-dark .mtk17 { color: #808080; }
.dark-default-dark .mtki { font-style: italic; }
.dark-default-dark .mtkb { font-weight: bold; }
.dark-default-dark .mtku { text-decoration: underline; text-underline-position: under; }
`}</style>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      